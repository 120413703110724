.App {
  text-align: left;
  font-family: 'Source Sans Pro', sans-serif !important;
  font-weight: 400;
  font-style: normal;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

body {
  margin: 0px !important;
  overflow-y: hidden !important;
}

.drawerPanel {
  border-radius: 5px;
}

.drawerContainer {
  padding: 24px;
}

.drawerHeaderContainer {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.drawerHeader {
  font-size: 20px;
}

.drawerCloseIcon {
  cursor: pointer;
  border-radius: 6px;
  padding: 3px;
  border: 1px solid #CED5D8;
}

.primaryButton {
  background-color: #01151D !important;
  color: #FFFFFF !important;
}

.secondaryButton {
  background-color: #FFFFFF !important;
  color: #01151D  !important;
  border: 1px solid #CED5D8 !important;
}

.rs-input-group.rs-input-group-inside .rs-input {
  height: 100%;
}

.rs-input-group.rs-input-group-inside {
  align-items: center;
}


::-webkit-scrollbar {
  width: 6px;
  border-radius: 3px;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #FFFFFF;
  border-radius: 10px;
}


::-webkit-scrollbar-thumb {
  border-radius: 3px;
  background:  rgba(63, 67, 80, 0.24);
}

::-webkit-scrollbar-thumb:hover {
  background: rgba(63, 67, 80, 0.3);
}
.rs-modal-footer .rs-btn+.rs-btn  {
  margin-left: 15px !important;

}