.activityModal {
  width: 640px !important;
  margin: 30px auto 0;
  outline: 0;
  overflow: visible;
  position: relative;
  width: auto;
}

.deleteModal {
  display: flex;
  font-size: 16px;
  gap: 8px;
  width: 312px;
  position: absolute;
  margin-top: 40%;
  margin-left: 25%;
}

.sol-dialog__body {
  overflow-x: hidden !important;
}

.sol-dropdown__list-container {
  left: 0 !important;
  z-index: 999 !important;
}

.ActivityHeaderButton {
  width: 169px;
  height: 20px;
  padding: 12px 16px 12px 16px;
  border-radius: 8px;
  gap: 8px;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.0025em;
  text-align: left;
  justify-content: center;
  align-items: center;
  display: flex;
  background-color: #1E697614;
  color: #01151C;
}

.ActivityHeaderButtons {
  display: flex;
  gap: 20px;
  margin: 20px 0px;
}

.ActivityFooterButtons {
  display: flex;
  margin: 5px 0px;
}

.ActivityFooterButtonsDesktop {
  display: flex;
  border-top: #DFE5E8 1px solid;
  padding-top: 10px;
}

.ActivityFooterButton {
  width: 390px;
  height: 20px;
  padding: 12px 16px 12px 16px;
  border-radius: 8px;
  gap: 8px;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.0025em;
  text-align: left;
  justify-content: center;
  align-items: center;
  display: flex;
  background-color: #1E697614;
}

.ActivityFooterButtonDesktop {
  padding: 6px 10px;
  border-radius: 8px;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.0025em;
  background-color: white;
  border: #DFE5E8 1px solid;
}

.chkBox {
  margin-top: -20px;
  width: 16px;
  height: 16px;
  gap: 0px;
}

.chkBox .hydrated {
  margin-top: 20px;
}

.checkboxLabel {
  position: relative;
  font-family: Source Sans Pro;
  font-size: 20px;
  font-weight: 400;
  line-height: 0px;
  letter-spacing: -0.005em;
  text-align: left;
  color: #01151C;
  margin-left: 25px;
}

.checkboxLabelDesktop {
  position: relative;
  font-family: Source Sans Pro;
  font-size: 20px;
  font-weight: 400;
  line-height: 0px;
  letter-spacing: -0.005em;
  text-align: left;
  color: #3A3E43;
  margin-left: 25px;
}

.substantialText {
  font-family: Source Sans Pro;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: -0.0025em;
  text-align: left;
  color: #667579;
  padding-left: 25px;
}


.descTextArea {
  border: 1px solid #CED6D8;
  width: 97%;
  height: Fixed (100px)px;
  gap: 0px;
  border-radius: 6px 6px 6px 6px;
  border: 1px 0px 0px 0px;
  background: #FFFFFF;
  color: #66747a;
  font-family: Source Sans Pro;
  font-size: 18px;
  padding-left: 15px;
  display: block;
}

.descTextArea:focus {
  outline: none !important;
  border: 1.1px solid #0C7BA1;
  /* box-shadow: 0 0 10px #719ECE; */
}

.descTextArea:focus::placeholder {
  color: #0C7BA1;
}

.descTextArea::placeholder {
  color: #66747a;
  font-family: Source Sans Pro;
  font-size: 18px;
}



.react-autosuggest__input {
  border: 1px solid #CED6D8 !important;
  width: 350px;
  height: 40px;
  gap: 0px;
  border-radius: 3px 3px 3px 3px;
  border: 1px 0px 0px 0px;
  background: #FFFFFF;
}

.background-toggle-container {
  text-align: left;
  height: 30px;
}

.sol-icon-check {
  margin: 10px 0 0 0;
}

.sol-text-field__content {
  padding-top: 20px !important;
}

.dvNextCallDate {
  width: Fill (350px)px;
  height: Hug (256px)px;
  gap: 16px;
  border-radius: 12px 12px 12px 12px;
}

.fieldSpacer {
  width: Fill (350px)px;
  height: Fill (32px)px;
  padding: 14px 0px 0px 0px;
  gap: 8px;

}

.visible-checkbox {
  margin: 10px 0 0 0 !important;
}

.react-datepicker-popper {
  z-index: 9999 !important;
  margin-left: 5%
}

.suggestion-highlighted {
  background-color: #b1d3f124;
}

.auto-suggest-input-error {
  border: 1px solid #b0133a;
  display: block;
  width: -webkit-fill-available !important;
  color: #575757;
  background-color: #fff;
  background-image: none;
  -webkit-transition: border-color .3s ease-in-out;
  transition: border-color .3s ease-in-out;
  border-radius: 6px;
  padding: 7px 11px;
  font-size: 16px;
  line-height: 1.42857143;
}

.auto-suggest-input:focus {
  outline: none !important;
  border: 1.1px solid #0C7BA1;
}

.auto-suggest-input-padding {
  border: 1px solid #e5e5ea;
  display: block;
  width: -webkit-fill-available !important;
  color: #575757;
  background-color: #fff;
  background-image: none;
  -webkit-transition: border-color .3s ease-in-out;
  transition: border-color .3s ease-in-out;
  border-radius: 6px;
  padding: 7px 30px 7px 11px;
  font-size: 16px;
  line-height: 1.42857143;
}

.auto-suggest-input {
  border: 1px solid #e5e5ea;
  display: block;
  width: -webkit-fill-available !important;
  color: #575757;
  background-color: #fff;
  background-image: none;
  -webkit-transition: border-color .3s ease-in-out;
  transition: border-color .3s ease-in-out;
  border-radius: 6px;
  padding: 7px 11px;
  font-size: 16px;
  line-height: 1.42857143;
}

.auto-suggest-container {
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, .65);
  overflow-x: hidden;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  -webkit-font-feature-settings: "tnum";
  font-feature-settings: "tnum";
  z-index: 1050;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  font-size: 16px;
  background-color: #fff;
  border-radius: 4px;
  outline: none;
  position: fixed;
  width: 350px;
  overflow-y: scroll;
  box-shadow: 0 2px 8px rgb(0 0 0 / 15%);
}

.auto-suggest-suggestion {
  position: relative;
  display: block;
  padding: 5px;
  height: 35px;
  overflow: hidden;
  color: rgba(0, 0, 0, .65);
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  white-space: nowrap;
  text-overflow: ellipsis;
  cursor: pointer;
  list-style-type: none !important;
  -webkit-transition: background .3s ease;
  transition: background .3s ease;
}

.redAsterisk {
  color: #b0133a;
}

.errorText {
  color: #b0133a;
  font-size: 15px;
}

.sol-text-field__input--input-readonly {
  cursor: pointer !important;
}

.react-datepicker-wrapper {
  width: 100%;
}

.react-datepicker__year-dropdown .react-datepicker__year-option:first-child::before {
    content: "\25B2";
    position: absolute;
    top: 5%;
    left: 45%;
    margin: auto;
  }
  
  .react-datepicker__year-dropdown::after {
    content: "\25BC";
    display: block;
    position: relative;
    bottom: 15%;
  }

.react-datepicker__year-dropdown::after {
  content: "\25BC";
  display: block;
  position: relative;
  bottom: 15%;
}

.sol-dialog__backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: var(--surface-utilities-overlays-backdrop, rgba(51, 68, 74, 0.3));
  z-index: var(--sol-elevation-dialog-backdrop, 1300);
}

.ActivityDialogDesktop {
  width: 640px !important;
}

.ActivityCheckboxes {
  display: flex;
  align-items: center;
  gap: 20px;
}

.rs-drawer-backdrop,
.rs-drawer-wrapper {
  z-index: 1 !important;
}

.ActivityModalDekstopInput {
  height: 42px !important;
  font-size: 16px !important;
  border: 1px solid #CED5D8 !important;
  transition: none !important;
  width: -webkit-fill-available;
}

.deleteModalIcon {
  width: 44px;
  height: 44px;
  border-radius: 40px;
  background: var(--surface-rag-danger-subdued-alpha, #FF1A530D);
  align-content: center;
  text-align: center;
}

.descriptionTooltip {
  color: #637177;
  display: flex;
  align-items: center;
  gap: 4px;
  margin-top: 5px;
}

.activityModalLabel {
  font-family: Source Sans Pro;
  font-size: 16px;
  font-weight: 400;
  line-height: 30px;
  letter-spacing: -0.0025em;
  text-align: left;
  color: #01151D;
  margin-bottom: 5px;
}

.rs-deleteModal {
  display: flex;
  gap: 10px;
  margin-bottom: 20px;
}

.deleteModalBackdrop {
  z-index: 1050 !important;
}

.deleteModalPrimaryBtn {
  background-color: #01151D !important;
  color: #FFFFFF !important;
  width: 128px;
}

.deleteModalSecondaryBtn {
  background-color: #FFFFFF !important;
  color: #01151D  !important;
  border: 1px solid #CED5D8 !important;
  width: 128px;
}