.aeroGridContainer {
  height: 80vh;
}


table {
  border-collapse: collapse;

}

th {
  color: #637177;
  font-weight: 500;
  border: none !important;
  height: 35px;
  text-align: left;
}

th,
td {
  width: 200px;
  text-align: left;
}

th {
  padding: 5px 20px;
}

.tableContainer {
  width: 100%;
  max-height: 70vh;
}

.tableHeaderContainer {
  background-color: #F6F9FA;
  border-radius: 8px;
  position: sticky;
  top: 0;
  z-index: 10;

}

th>td:first-child {
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
}

.tableDescription,
.tableNoDescription {
  margin-bottom: 15px;
  border-bottom: 1px solid #DFE5E8;
  border-left: 1px solid #DFE5E8;
  border-right: 1px solid #DFE5E8;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  padding: 10px 20px;
  text-align: justify;
  color: #637177;

}

.descriptionData {
  padding: 0px !important;
}

.tableDetailsRowData {
  color: #01151D;
}

.tableCell {
  padding: 10px 20px !important;
  border-top: 1px solid #DFE5E8;
  border-bottom: 1px solid #DFE5E8;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  align-content: center;
  min-height: 34px;
}



.tableNoDescription {
  height: 1px;
  margin-bottom: 15px !important;
  padding: 0px !important;
  border-bottom: 0px solid #DFE5E8;
}

.tableHeader {
  margin-bottom: 10px;
}

.emptyRow {
  background-color: #FFF !important;
}

.headerFixed {
  position: sticky !important;
  top: 0 !important;
}

.tableCellBorderRadiusLeft {
  border-top-left-radius: 8px;
  border-left: 1px solid #DFE5E8;
}

.tableCellBorderRadiusRight {
  border-top-right-radius: 8px;
  border-right: 1px solid #DFE5E8;
}

.tableCellBorderRadiusLeftBottom {
    border-bottom-left-radius: 8px;
}

.tableCellBorderRadiusRightBottom {
    border-bottom-right-radius: 8px;
}

.tableCellBorderRadiusRightTop {
  border-top-right-radius: 8px;

}

.tableHeaderBorderRadiusLeft {
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
}

.tableHeaderBorderRadiusRight {
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
}

.fontWeight500 {
  font-weight: 500 !important;
  color: #01151D;
  font-family: "Source Sans 3";

}

.rs-pagination-btn-active {
  color: #000000 !important;
}

.rs-pagination-btn.rs-pagination-btn-active {
  border: none !important;
  background-color: #EAEFF1 !important;
  box-shadow: none !important;
}

.rs-pagination-btn.rs-pagination-btn-active:hover {
  border: none !important;
}

.tooltipIcon {
  color: #637177;
  margin-left: 5px;
}

.gridEditIcon {
  color: #01151D;
  background-color: #EAEFF1;
  border-radius: 3px;
  padding: 4px;
  cursor: pointer;
}

.editColumn {
  text-align: right;
}

.sortableColumn {
  display: flex;
}

.rs-dropdown-item:focus-visible {
  background-color: #09779E;
  opacity: 0.07;
}